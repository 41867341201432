import { Injectable } from '@angular/core';
import { saveAs as importedSaveAs } from 'file-saver';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  constructor(private api: ApiService) {}

  downloadQrCodesGenerationXlsFile(id: string) {
    this.api.downloadXlsFile(id).subscribe((response: Blob) => {
      importedSaveAs(response, `${id}.xls`);
    });
  }

  downloadCaseQrCodesGenerationXlsFile(id: string) {
    this.api.downloadQrCaseXlsFile(id).subscribe((response: Blob) => {
      importedSaveAs(response, `${id}.xls`);
    });
  }
}
