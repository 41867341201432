var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { environment } from '../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ModuleEnum } from '../enums/module.enum';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ApiService = /** @class */ (function () {
    function ApiService(http) {
        this.http = http;
        this.apiUrl = environment.apiUrl;
    }
    ApiService.prototype.logIn = function (payload) {
        return this.http.post(this.apiUrl + "/auth/admin/logIn", payload);
    };
    ApiService.prototype.getPatternTypes = function (payload) {
        var params = new HttpParams({
            fromObject: {
                page: String(payload.page),
                limit: String(payload.limit),
                deleted: String(payload.deleted),
            },
        });
        if (payload.query) {
            params = params.append('query', payload.query);
        }
        return this.http.get(this.apiUrl + "/patternType", { params: params });
    };
    ApiService.prototype.getPatternType = function (id) {
        return this.http.get(this.apiUrl + "/patternType/" + id);
    };
    ApiService.prototype.savePatternType = function (patternType) {
        return this.http.post(this.apiUrl + "/patternType", __assign({}, patternType));
    };
    ApiService.prototype.editPatternType = function (id, patternType) {
        return this.http.put(this.apiUrl + "/patternType/" + id, __assign({}, patternType));
    };
    ApiService.prototype.deletePatternType = function (id) {
        return this.http.delete(this.apiUrl + "/patternType/" + id);
    };
    ApiService.prototype.getQrCodes = function (payload) {
        var params = new HttpParams({
            fromObject: {
                page: String(payload.page),
                limit: String(payload.limit),
                deleted: String(payload.deleted),
            },
        });
        if (payload.query) {
            params = params.append('query', payload.query);
        }
        return this.http.get(this.apiUrl + "/qr-code", { params: params });
    };
    ApiService.prototype.getQrCodesGenerations = function (payload) {
        var params = new HttpParams({
            fromObject: {
                page: String(payload.page),
                limit: String(payload.limit),
                deleted: String(payload.deleted),
            },
        });
        if (payload.isExchange) {
            params = params.append('isExchange', String(payload.isExchange));
        }
        if (payload.material) {
            params = params.append('material', payload.material);
        }
        if (payload.qrCodeType) {
            params = params.append('qrCodeType', payload.qrCodeType);
        }
        return this.http.get(this.apiUrl + "/qr-codes-generation", { params: params });
    };
    ApiService.prototype.createQrCodesGeneration = function (qrCodesGenerationCreateDto) {
        return this.http.post(this.apiUrl + "/qr-codes-generation", qrCodesGenerationCreateDto);
    };
    ApiService.prototype.downloadXlsFile = function (id) {
        return this.http.get(this.apiUrl + "/qr-codes-generation/" + id + "/xls", {
            responseType: 'blob',
        });
    };
    ApiService.prototype.downloadQrCaseXlsFile = function (id) {
        return this.http.get(this.apiUrl + "/case-qr-codes-generation/" + id + "/xls", {
            responseType: 'blob',
        });
    };
    ApiService.prototype.getCompanies = function (payload) {
        var params = new HttpParams({
            fromObject: {
                page: String(payload.page),
                limit: String(payload.limit),
                deleted: String(payload.deleted),
            },
        });
        if (payload.query) {
            params = params.append('query', payload.query);
        }
        return this.http.get(this.apiUrl + "/company", { params: params });
    };
    ApiService.prototype.saveCompany = function (company) {
        return this.http.post(this.apiUrl + "/company", __assign({}, company));
    };
    ApiService.prototype.getOneCompany = function (id) {
        return this.http.get(this.apiUrl + "/company/" + id);
    };
    ApiService.prototype.editCompany = function (company) {
        return this.http.put(this.apiUrl + "/company/" + company.id, __assign({}, company));
    };
    ApiService.prototype.searchSalesMan = function (query) {
        var params = new HttpParams({
            fromObject: {
                query: query,
            },
        });
        return this.http.get(this.apiUrl + "/sales-man/findWithQuery", { params: params });
    };
    ApiService.prototype.deleteCompany = function (id) {
        return this.http.delete(this.apiUrl + "/company/" + id);
    };
    ApiService.prototype.changeCompanyPassword = function (newPassword) {
        return this.http.put(this.apiUrl + "/company/" + newPassword.id + "/changePassword", __assign({}, newPassword));
    };
    ApiService.prototype.sendResetDbCommand = function (id) {
        return this.http.post(this.apiUrl + "/command/" + id, {});
    };
    ApiService.prototype.changeEnvironment = function (id, isNext) {
        return this.http.post(this.apiUrl + "/command/" + id + "/environment", {
            isNext: isNext,
        });
    };
    ApiService.prototype.getCompanyAndSalesPoints = function (id) {
        return this.http.get(this.apiUrl + "/company/" + id + "/salesPoints");
    };
    ApiService.prototype.addSalesPoint = function (salesPoint) {
        return this.http.post(this.apiUrl + "/salesPoint", __assign({}, salesPoint));
    };
    ApiService.prototype.getOneSalesPoint = function (id) {
        return this.http.get(this.apiUrl + "/salesPoint/" + id);
    };
    ApiService.prototype.editSalesPoint = function (salesPoint) {
        return this.http.put(this.apiUrl + "/salesPoint/" + salesPoint.id, __assign({}, salesPoint));
    };
    ApiService.prototype.deleteSalesPoint = function (id) {
        return this.http.delete(this.apiUrl + "/salesPoint/" + id);
    };
    ApiService.prototype.changeSalesPointPassword = function (newPassword) {
        return this.http.put(this.apiUrl + "/salesPoint/changePassword/" + newPassword.id, __assign({}, newPassword));
    };
    ApiService.prototype.getTechnicians = function (payload) {
        var params = new HttpParams({
            fromObject: {
                page: String(payload.page),
                limit: String(payload.limit),
                deleted: String(payload.deleted),
            },
        });
        if (payload.query) {
            params = params.append('query', payload.query);
        }
        return this.http.get(this.apiUrl + "/technician", { params: params });
    };
    ApiService.prototype.deleteTechnician = function (id) {
        return this.http.delete(this.apiUrl + "/technician/" + id);
    };
    ApiService.prototype.addTechnician = function (technician) {
        return this.http.post(this.apiUrl + "/technician", __assign({}, technician));
    };
    ApiService.prototype.changeTechnicianPassword = function (newPassword) {
        return this.http.put(this.apiUrl + "/technician/" + newPassword.id + "/changePassword", __assign({}, newPassword));
    };
    ApiService.prototype.getOneTechnician = function (id) {
        return this.http.get(this.apiUrl + "/technician/" + id);
    };
    ApiService.prototype.editTechnician = function (technician) {
        return this.http.put(this.apiUrl + "/technician/" + technician.id, __assign({}, technician));
    };
    ApiService.prototype.getMaterialGroups = function (payload) {
        var params = new HttpParams({
            fromObject: {
                page: String(payload.page),
                limit: String(payload.limit),
                deleted: String(payload.deleted),
            },
        });
        if (payload.query) {
            params = params.append('query', payload.query);
        }
        return this.http.get(this.apiUrl + "/materialGroup", { params: params });
    };
    ApiService.prototype.getMaterialGroupMaterials = function (id) {
        return this.http.get(this.apiUrl + "/materialGroup/" + id + "/materials");
    };
    ApiService.prototype.saveMaterialGroup = function (materialGroup) {
        return this.http.post(this.apiUrl + "/materialGroup", __assign({}, materialGroup));
    };
    ApiService.prototype.getOneMaterialGroup = function (id) {
        return this.http.get(this.apiUrl + "/materialGroup/" + id);
    };
    ApiService.prototype.editMaterialGroup = function (id, material) {
        return this.http.put(this.apiUrl + "/materialGroup/" + id, __assign({ id: id }, material));
    };
    ApiService.prototype.deleteMaterialGroup = function (id) {
        return this.http.delete(this.apiUrl + "/materialGroup/" + id);
    };
    ApiService.prototype.getMaterials = function (payload) {
        var params = new HttpParams({
            fromObject: {
                page: String(payload.page),
                limit: String(payload.limit),
                deleted: String(payload.deleted),
            },
        });
        if (payload.query) {
            params = params.append('query', payload.query);
        }
        return this.http.get(this.apiUrl + "/material", { params: params });
    };
    ApiService.prototype.saveMaterial = function (material) {
        return this.http.post(this.apiUrl + "/material", __assign({}, material));
    };
    ApiService.prototype.getOneMaterial = function (id) {
        return this.http.get(this.apiUrl + "/material/" + id);
    };
    ApiService.prototype.editMaterial = function (id, material) {
        return this.http.put(this.apiUrl + "/material/" + id, __assign({ id: id }, material));
    };
    ApiService.prototype.checkIfPrefixUnique = function (prefix) {
        return this.http.get(this.apiUrl + "/material/checkPrefix/" + prefix);
    };
    ApiService.prototype.getAllMaterials = function () {
        return this.http.get(this.apiUrl + "/material/all");
    };
    ApiService.prototype.getSalesMan = function (payload) {
        var params = new HttpParams({
            fromObject: {
                page: String(payload.page),
                limit: String(payload.limit),
                deleted: String(payload.deleted),
            },
        });
        if (payload.query) {
            params = params.append('query', payload.query);
        }
        return this.http.get(this.apiUrl + "/sales-man", { params: params });
    };
    ApiService.prototype.deleteSalesMan = function (id) {
        return this.http.delete(this.apiUrl + "/sales-man/" + id);
    };
    ApiService.prototype.addSalesMan = function (salesMan) {
        return this.http.post(this.apiUrl + "/sales-man", __assign({}, salesMan));
    };
    ApiService.prototype.changeSalesManPassword = function (newPassword) {
        return this.http.put(this.apiUrl + "/sales-man/" + newPassword.id + "/changePassword", __assign({}, newPassword));
    };
    ApiService.prototype.getOneSalesMan = function (id) {
        return this.http.get(this.apiUrl + "/sales-man/" + id);
    };
    ApiService.prototype.editSalesMan = function (salesMan) {
        return this.http.put(this.apiUrl + "/sales-man/" + salesMan.id, __assign({}, salesMan));
    };
    ApiService.prototype.getSalesManWithCompanies = function (id) {
        return this.http.get(this.apiUrl + "/sales-man/" + id + "/withCompanies");
    };
    ApiService.prototype.getCompanySalesReport = function (dates) {
        var params = new HttpParams({
            fromObject: {
                startAt: dates.startAt.toISOString(),
                endAt: dates.endAt.toISOString(),
            },
        });
        return this.http.get(this.apiUrl + "/admin/report/companies/successful", {
            params: params,
            responseType: 'blob',
        });
    };
    ApiService.prototype.getCompanyErrorsReport = function (dates) {
        var params = new HttpParams({
            fromObject: {
                startAt: dates.startAt.toISOString(),
                endAt: dates.endAt.toISOString(),
            },
        });
        return this.http.get(this.apiUrl + "/admin/report/companies/failed", {
            params: params,
            responseType: 'blob',
        });
    };
    ApiService.prototype.getCompanyExchangesReport = function (dates) {
        var params = new HttpParams({
            fromObject: {
                startAt: dates.startAt.toISOString(),
                endAt: dates.endAt.toISOString(),
            },
        });
        return this.http.get(this.apiUrl + "/admin/report/companies/exchanges", {
            params: params,
            responseType: 'blob',
        });
    };
    ApiService.prototype.getCompanyAssemblyErrorsReport = function (dates) {
        var params = new HttpParams({
            fromObject: {
                startAt: dates.startAt.toISOString(),
                endAt: dates.endAt.toISOString(),
            },
        });
        return this.http.get(this.apiUrl + "/admin/report/companies/assemblyErrors", {
            params: params,
            responseType: 'blob',
        });
    };
    ApiService.prototype.getSalesManSalesReport = function (dates) {
        var params = new HttpParams({
            fromObject: {
                startAt: dates.startAt.toISOString(),
                endAt: dates.endAt.toISOString(),
            },
        });
        return this.http.get(this.apiUrl + "/admin/report/sales-men/successful", {
            params: params,
            responseType: 'blob',
        });
    };
    ApiService.prototype.getSalesManErrorsReport = function (dates) {
        var params = new HttpParams({
            fromObject: {
                startAt: dates.startAt.toISOString(),
                endAt: dates.endAt.toISOString(),
            },
        });
        return this.http.get(this.apiUrl + "/admin/report/sales-men/failed", {
            params: params,
            responseType: 'blob',
        });
    };
    ApiService.prototype.getSalesManExchangesReport = function (dates) {
        var params = new HttpParams({
            fromObject: {
                startAt: dates.startAt.toISOString(),
                endAt: dates.endAt.toISOString(),
            },
        });
        return this.http.get(this.apiUrl + "/admin/report/sales-men/exchanges", {
            params: params,
            responseType: 'blob',
        });
    };
    ApiService.prototype.getSalesManAssemblyErrorsReport = function (dates) {
        var params = new HttpParams({
            fromObject: {
                startAt: dates.startAt.toISOString(),
                endAt: dates.endAt.toISOString(),
            },
        });
        return this.http.get(this.apiUrl + "/admin/report/sales-men/assemblyErrors", {
            params: params,
            responseType: 'blob',
        });
    };
    ApiService.prototype.getOneSalesManSalesReport = function (id, dates) {
        var params = new HttpParams({
            fromObject: {
                startAt: dates.startAt.toISOString(),
                endAt: dates.endAt.toISOString(),
            },
        });
        return this.http.get(this.apiUrl + "/admin/report/sales-man/" + id + "/successful", {
            params: params,
            responseType: 'blob',
        });
    };
    ApiService.prototype.getOneSalesManErrorsReport = function (id, dates) {
        var params = new HttpParams({
            fromObject: {
                startAt: dates.startAt.toISOString(),
                endAt: dates.endAt.toISOString(),
            },
        });
        return this.http.get(this.apiUrl + "/admin/report/sales-man/" + id + "/failed", {
            params: params,
            responseType: 'blob',
        });
    };
    ApiService.prototype.getOneSalesManExchangesReport = function (id, dates) {
        var params = new HttpParams({
            fromObject: {
                startAt: dates.startAt.toISOString(),
                endAt: dates.endAt.toISOString(),
            },
        });
        return this.http.get(this.apiUrl + "/admin/report/sales-man/" + id + "/exchanges", {
            params: params,
            responseType: 'blob',
        });
    };
    ApiService.prototype.getOneSalesManAssemblyErrorsReport = function (id, dates) {
        var params = new HttpParams({
            fromObject: {
                startAt: dates.startAt.toISOString(),
                endAt: dates.endAt.toISOString(),
            },
        });
        return this.http.get(this.apiUrl + "/admin/report/sales-man/" + id + "/assemblyErrors", {
            params: params,
            responseType: 'blob',
        });
    };
    ApiService.prototype.getOneCompanySalesReport = function (id, dates) {
        var params = new HttpParams({
            fromObject: {
                startAt: dates.startAt.toISOString(),
                endAt: dates.endAt.toISOString(),
            },
        });
        return this.http.get(this.apiUrl + "/admin/report/company/" + id + "/successful", {
            params: params,
            responseType: 'blob',
        });
    };
    ApiService.prototype.getOneCompanyErrorsReport = function (id, dates) {
        var params = new HttpParams({
            fromObject: {
                startAt: dates.startAt.toISOString(),
                endAt: dates.endAt.toISOString(),
            },
        });
        return this.http.get(this.apiUrl + "/admin/report/company/" + id + "/failed", {
            params: params,
            responseType: 'blob',
        });
    };
    ApiService.prototype.getOneCompanyExchangesReport = function (id, dates) {
        var params = new HttpParams({
            fromObject: {
                startAt: dates.startAt.toISOString(),
                endAt: dates.endAt.toISOString(),
            },
        });
        return this.http.get(this.apiUrl + "/admin/report/company/" + id + "/exchanges", {
            params: params,
            responseType: 'blob',
        });
    };
    ApiService.prototype.getOneCompanyAssemblyErrorsReport = function (id, dates) {
        var params = new HttpParams({
            fromObject: {
                startAt: dates.startAt.toISOString(),
                endAt: dates.endAt.toISOString(),
            },
        });
        return this.http.get(this.apiUrl + "/admin/report/company/" + id + "/assemblyErrors", {
            params: params,
            responseType: 'blob',
        });
    };
    ApiService.prototype.getSyncAccessLog = function (payload) {
        var params = new HttpParams({
            fromObject: {
                page: String(payload.page),
                limit: String(payload.limit),
                deleted: String(payload.deleted),
            },
        });
        return this.http.get(this.apiUrl + "/access-log/synchronization/all", {
            params: params,
        });
    };
    ApiService.prototype.getSalesPointSyncAccessLog = function (id, payload) {
        var params = new HttpParams({
            fromObject: {
                page: String(payload.page),
                limit: String(payload.limit),
                deleted: String(payload.deleted),
            },
        });
        return this.http.get(this.apiUrl + "/access-log/synchronization/" + id, {
            params: params,
        });
    };
    ApiService.prototype.deleteSalesPointImei = function (id) {
        return this.http.get(this.apiUrl + "/salesPoint/" + id + "/deleteImei");
    };
    ApiService.prototype.getCaseQrCodes = function (payload) {
        var params = new HttpParams({
            fromObject: {
                page: String(payload.page),
                limit: String(payload.limit),
                deleted: String(payload.deleted),
            },
        });
        if (payload.query) {
            params = params.append('query', payload.query);
        }
        return this.http.get(this.apiUrl + "/case-qr-code", { params: params });
    };
    ApiService.prototype.getCaseQrCodesGenerations = function (payload) {
        var params = new HttpParams({
            fromObject: {
                page: String(payload.page),
                limit: String(payload.limit),
                deleted: String(payload.deleted),
            },
        });
        if (payload.feryaPatternId) {
            params = params.append('feryaPatternId', payload.feryaPatternId);
        }
        if (payload.deviceId) {
            params = params.append('deviceId', payload.deviceId);
        }
        return this.http.get(this.apiUrl + "/case-qr-codes-generation", { params: params });
    };
    ApiService.prototype.getAllDevices = function () {
        return this.http.get(this.apiUrl + "/device/admin");
    };
    ApiService.prototype.getDevicePatterns = function (id) {
        return this.http.post(this.apiUrl + "/pattern/admin", {
            deviceId: id,
            availableForModule: [ModuleEnum.FeryaCase, ModuleEnum.OnDemand],
        });
    };
    ApiService.prototype.getAllPatterns = function () {
        return this.http.post(this.apiUrl + "/pattern/admin", {
            deviceId: null,
            availableForModule: ModuleEnum.FeryaCover,
        });
    };
    ApiService.prototype.createCaseQrCodesGeneration = function (payload) {
        return this.http.post(this.apiUrl + "/case-qr-codes-generation", __assign({}, payload));
    };
    ApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApiService_Factory() { return new ApiService(i0.ɵɵinject(i1.HttpClient)); }, token: ApiService, providedIn: "root" });
    return ApiService;
}());
export { ApiService };
