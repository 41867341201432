import { Component, Input, OnInit } from '@angular/core';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { MenuItem } from 'primeng/api';
import { AppComponent } from './app.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-menu',
  template: `
    <ul
      app-submenu
      [item]="model"
      root="true"
      class="layout-menu"
      [reset]="reset"
      visible="true"
      parentActive="true"
    ></ul>
  `,
})
export class AppMenuComponent implements OnInit {
  @Input() reset: boolean;

  model: any[];

  constructor(
    public app: AppComponent,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    this.model = [
      {
        label: this.translateService.instant('menu.companies'),
        icon: 'fa fa-fw fa-dashboard',
        routerLink: ['/companies'],
      },
      {
        label: this.translateService.instant('menu.technicians'),
        icon: 'fa fa-fw fa-dashboard',
        routerLink: ['/technicians'],
      },
      {
        label: this.translateService.instant('menu.salesMan'),
        icon: 'fa fa-fw fa-dashboard',
        routerLink: ['/salesMan'],
      },
      {
        label: this.translateService.instant('menu.qrCodes'),
        icon: 'fa fa-fw fa-dashboard',
        routerLink: ['/qr-codes'],
      },
      {
        label: this.translateService.instant('menu.qrCodesGenerations'),
        icon: 'fa fa-fw fa-dashboard',
        routerLink: ['/qr-codes-generations'],
      },
      {
        label: this.translateService.instant('menu.materials'),
        icon: 'fa fa-fw fa-dashboard',
        routerLink: ['/materials'],
      },
      {
        label: this.translateService.instant('menu.materialGroup'),
        icon: 'fa fa-fw fa-dashboard',
        routerLink: ['/materialGroup'],
      },
      {
        label: this.translateService.instant('menu.reports'),
        icon: 'fa fa-fw fa-dashboard',
        routerLink: ['/reports'],
      },
      {
        label: this.translateService.instant('menu.syncAccessLog'),
        icon: 'fa fa-fw fa-dashboard',
        routerLink: ['/syncAccessLog'],
      },
      {
        label: this.translateService.instant('menu.caseQrCode'),
        icon: 'fa fa-fw fa-dashboard',
        routerLink: ['/caseQrCode'],
      },
      {
        label: this.translateService.instant('menu.caseQrCodesGeneration'),
        icon: 'fa fa-fw fa-dashboard',
        routerLink: ['/caseQrCodesGeneration'],
      },
      {
        label: this.translateService.instant('menu.patternTypes'),
        icon: 'fa fa-fw fa-dashboard',
        routerLink: ['/patternTypes'],
      },
    ];
  }
}

@Component({
  /* tslint:disable:component-selector */
  selector: '[app-submenu]',
  /* tslint:enable:component-selector */
  template: `
    <ng-template
      ngFor
      let-child
      let-i="index"
      [ngForOf]="root ? item : item.items"
    >
      <li
        [ngClass]="{ 'active-menuitem': isActive(i) }"
        [class]="child.badgeStyleClass"
        *ngIf="child.visible === false ? false : true"
      >
        <a
          [href]="child.url || '#'"
          (click)="itemClick($event, child, i)"
          (mouseenter)="onMouseEnter(i)"
          *ngIf="!child.routerLink"
          [ngClass]="child.styleClass"
          [attr.tabindex]="!visible ? '-1' : null"
          [attr.target]="child.target"
        >
          <i [ngClass]="child.icon"></i>
          <span>{{ child.label }}</span>
          <i
            class="fa fa-fw fa-angle-down layout-menuitem-toggler"
            *ngIf="child.items"
          ></i>
          <span class="menuitem-badge" *ngIf="child.badge">{{
            child.badge
          }}</span>
        </a>

        <a
          (click)="itemClick($event, child, i)"
          (mouseenter)="onMouseEnter(i)"
          *ngIf="child.routerLink"
          [routerLink]="child.routerLink"
          routerLinkActive="active-menuitem-routerlink"
          [routerLinkActiveOptions]="{ exact: true }"
          [attr.tabindex]="!visible ? '-1' : null"
          [attr.target]="child.target"
        >
          <i [ngClass]="child.icon"></i>
          <span>{{ child.label }}</span>
          <i
            class="fa fa-fw fa-angle-down layout-menuitem-toggler"
            *ngIf="child.items"
          ></i>
          <span class="menuitem-badge" *ngIf="child.badge">{{
            child.badge
          }}</span>
        </a>
        <div class="layout-menu-tooltip">
          <div class="layout-menu-tooltip-arrow"></div>
          <div class="layout-menu-tooltip-text">{{ child.label }}</div>
        </div>
        <ul
          app-submenu
          [item]="child"
          *ngIf="child.items"
          [visible]="isActive(i)"
          [reset]="reset"
          [parentActive]="isActive(i)"
          [@children]="
            (app.isSlim() || app.isHorizontal()) && app.isDesktop() && root
              ? isActive(i)
                ? 'visible'
                : 'hidden'
              : isActive(i)
              ? 'visibleAnimated'
              : 'hiddenAnimated'
          "
        ></ul>
      </li>
    </ng-template>
  `,
  animations: [
    trigger('children', [
      state(
        'hiddenAnimated',
        style({
          height: '0px',
        }),
      ),
      state(
        'visibleAnimated',
        style({
          height: '*',
        }),
      ),
      state(
        'visible',
        style({
          height: '*',
          'z-index': 100,
        }),
      ),
      state(
        'hidden',
        style({
          height: '0px',
          'z-index': '*',
        }),
      ),
      transition(
        'visibleAnimated => hiddenAnimated',
        animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'),
      ),
      transition(
        'hiddenAnimated => visibleAnimated',
        animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'),
      ),
    ]),
  ],
})
export class AppSubMenuComponent {
  @Input() item: MenuItem;

  @Input() root: boolean;

  @Input() visible: boolean;

  _parentActive: boolean;

  _reset: boolean;

  activeIndex: number;

  constructor(public app: AppComponent) {}

  itemClick(event: Event, item: MenuItem, index: number) {
    if (this.root) {
      this.app.menuHoverActive = !this.app.menuHoverActive;
    }
    // avoid processing disabled items
    if (item.disabled) {
      event.preventDefault();
      return true;
    }

    // activate current item and deactivate active sibling if any
    this.activeIndex = this.activeIndex === index ? null : index;

    // execute command
    if (item.command) {
      item.command({ originalEvent: event, item: item });
    }

    // prevent hash change
    if (item.items || (!item.url && !item.routerLink)) {
      setTimeout(() => {
        this.app.layoutMenuScrollerViewChild.moveBar();
      }, 450);
      event.preventDefault();
    }

    // hide menu
    if (!item.items) {
      if (this.app.isHorizontal() || this.app.isSlim()) {
        this.app.resetMenu = true;
      } else {
        this.app.resetMenu = false;
      }

      this.app.overlayMenuActive = false;
      this.app.staticMenuMobileActive = false;
      this.app.menuHoverActive = !this.app.menuHoverActive;
    }
  }

  onMouseEnter(index: number) {
    if (
      this.root &&
      this.app.menuHoverActive &&
      (this.app.isHorizontal() || this.app.isSlim()) &&
      !this.app.isMobile() &&
      !this.app.isTablet()
    ) {
      this.activeIndex = index;
    }
  }

  isActive(index: number): boolean {
    return this.activeIndex === index;
  }

  @Input() get reset(): boolean {
    return this._reset;
  }

  set reset(val: boolean) {
    this._reset = val;

    if (this._reset && (this.app.isHorizontal() || this.app.isSlim())) {
      this.activeIndex = null;
    }
  }

  @Input() get parentActive(): boolean {
    return this._parentActive;
  }

  set parentActive(val: boolean) {
    this._parentActive = val;

    if (!this._parentActive) {
      this.activeIndex = null;
    }
  }
}
