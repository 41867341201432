import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { environment } from '../environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    let authResponse: any = localStorage.getItem('auth');
    if (authResponse) {
      authResponse = JSON.parse(authResponse);
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${authResponse.accessToken}`,
          'Content-Type': 'application/json',
          Accept: '*/*',
        },
      });
    }
    return next.handle(request);
  }
}

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private msgService: MessageService,
    private translate: TranslateService,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        (event: any) => {},
        (error) => {
          if (error.status === 401) {
            return this.router.navigateByUrl('/login');
          }
          if (error.status === 403) {
            this.msgService.add({
              severity: 'error',
              summary: this.translate.instant('errors.header'),
              detail: this.translate.instant('errors.forbidden'),
            });
            return this.router.navigateByUrl('');
          }
          const urls = [environment.apiUrl + '/auth/technician/logIn'];
          if (!urls.includes(error.url)) {
            console.log(error);

            let detail;
            if (
              error.error &&
              error.error.errors &&
              error.error.errors.message
            ) {
              detail = error.error.errors.message;
            }
            // TO DO, switch to only some error types
            this.msgService.add({
              severity: 'error',
              summary: this.translate.instant('errors.header'),
              detail: detail,
            });
          }
        },
      ),
    );
  }
}
