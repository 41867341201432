import { OnInit } from '@angular/core';
import { AppComponent } from './app.component';
import { TranslateService } from '@ngx-translate/core';
var AppMenuComponent = /** @class */ (function () {
    function AppMenuComponent(app, translateService) {
        this.app = app;
        this.translateService = translateService;
    }
    AppMenuComponent.prototype.ngOnInit = function () {
        this.model = [
            {
                label: this.translateService.instant('menu.companies'),
                icon: 'fa fa-fw fa-dashboard',
                routerLink: ['/companies'],
            },
            {
                label: this.translateService.instant('menu.technicians'),
                icon: 'fa fa-fw fa-dashboard',
                routerLink: ['/technicians'],
            },
            {
                label: this.translateService.instant('menu.salesMan'),
                icon: 'fa fa-fw fa-dashboard',
                routerLink: ['/salesMan'],
            },
            {
                label: this.translateService.instant('menu.qrCodes'),
                icon: 'fa fa-fw fa-dashboard',
                routerLink: ['/qr-codes'],
            },
            {
                label: this.translateService.instant('menu.qrCodesGenerations'),
                icon: 'fa fa-fw fa-dashboard',
                routerLink: ['/qr-codes-generations'],
            },
            {
                label: this.translateService.instant('menu.materials'),
                icon: 'fa fa-fw fa-dashboard',
                routerLink: ['/materials'],
            },
            {
                label: this.translateService.instant('menu.materialGroup'),
                icon: 'fa fa-fw fa-dashboard',
                routerLink: ['/materialGroup'],
            },
            {
                label: this.translateService.instant('menu.reports'),
                icon: 'fa fa-fw fa-dashboard',
                routerLink: ['/reports'],
            },
            {
                label: this.translateService.instant('menu.syncAccessLog'),
                icon: 'fa fa-fw fa-dashboard',
                routerLink: ['/syncAccessLog'],
            },
            {
                label: this.translateService.instant('menu.caseQrCode'),
                icon: 'fa fa-fw fa-dashboard',
                routerLink: ['/caseQrCode'],
            },
            {
                label: this.translateService.instant('menu.caseQrCodesGeneration'),
                icon: 'fa fa-fw fa-dashboard',
                routerLink: ['/caseQrCodesGeneration'],
            },
            {
                label: this.translateService.instant('menu.patternTypes'),
                icon: 'fa fa-fw fa-dashboard',
                routerLink: ['/patternTypes'],
            },
        ];
    };
    return AppMenuComponent;
}());
export { AppMenuComponent };
var AppSubMenuComponent = /** @class */ (function () {
    function AppSubMenuComponent(app) {
        this.app = app;
    }
    AppSubMenuComponent.prototype.itemClick = function (event, item, index) {
        var _this = this;
        if (this.root) {
            this.app.menuHoverActive = !this.app.menuHoverActive;
        }
        // avoid processing disabled items
        if (item.disabled) {
            event.preventDefault();
            return true;
        }
        // activate current item and deactivate active sibling if any
        this.activeIndex = this.activeIndex === index ? null : index;
        // execute command
        if (item.command) {
            item.command({ originalEvent: event, item: item });
        }
        // prevent hash change
        if (item.items || (!item.url && !item.routerLink)) {
            setTimeout(function () {
                _this.app.layoutMenuScrollerViewChild.moveBar();
            }, 450);
            event.preventDefault();
        }
        // hide menu
        if (!item.items) {
            if (this.app.isHorizontal() || this.app.isSlim()) {
                this.app.resetMenu = true;
            }
            else {
                this.app.resetMenu = false;
            }
            this.app.overlayMenuActive = false;
            this.app.staticMenuMobileActive = false;
            this.app.menuHoverActive = !this.app.menuHoverActive;
        }
    };
    AppSubMenuComponent.prototype.onMouseEnter = function (index) {
        if (this.root &&
            this.app.menuHoverActive &&
            (this.app.isHorizontal() || this.app.isSlim()) &&
            !this.app.isMobile() &&
            !this.app.isTablet()) {
            this.activeIndex = index;
        }
    };
    AppSubMenuComponent.prototype.isActive = function (index) {
        return this.activeIndex === index;
    };
    Object.defineProperty(AppSubMenuComponent.prototype, "reset", {
        get: function () {
            return this._reset;
        },
        set: function (val) {
            this._reset = val;
            if (this._reset && (this.app.isHorizontal() || this.app.isSlim())) {
                this.activeIndex = null;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppSubMenuComponent.prototype, "parentActive", {
        get: function () {
            return this._parentActive;
        },
        set: function (val) {
            this._parentActive = val;
            if (!this._parentActive) {
                this.activeIndex = null;
            }
        },
        enumerable: true,
        configurable: true
    });
    return AppSubMenuComponent;
}());
export { AppSubMenuComponent };
