import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LoginModel } from '@3mk/shared/models/login.model';

import {
  CaseQrCodeGenerationPagination,
  Pagination,
  QrCodeGenerationPagination,
} from '@3mk/shared/models/pagination.model';

import { QrCodesGenerationCreateDto } from '../pages/qr-codes-generations/dto/qr-codes-generation-create.dto';
import { Observable } from 'rxjs';
import { CompanyModel } from '@3mk/shared/models/company.model';
import { SalesPointModel } from '@3mk/shared/models/SalesPoint.model';
import { NewPasswordModel } from '@3mk/shared/models/newPassword.model';
import { TechnicianModel } from '@3mk/shared/models/Technician.model';
import { Material } from '@3mk/shared/models/material.model';
import { SalesManModel } from '@3mk/shared/models/SalesMan.model';
import { DatesRangeDTO } from '../dto/date-range.dto';
import { CaseQrCodesGenerationCreateDto } from '../pages/case-qr-codes-generation/dto/case-qr-codes-generation-create.dto';
import { ModuleEnum } from '../enums/module.enum';
import { MaterialGroup } from '@3mk/shared/models/MaterialGroup.model';
import { PatternTypeI } from '@3mk/shared/models/patternType.model';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  logIn(payload: LoginModel) {
    return this.http.post(`${this.apiUrl}/auth/admin/logIn`, payload);
  }

  getPatternTypes(payload: Pagination) {
    let params = new HttpParams({
      fromObject: {
        page: String(payload.page),
        limit: String(payload.limit),
        deleted: String(payload.deleted),
      },
    });
    if (payload.query) {
      params = params.append('query', payload.query);
    }
    return this.http.get(`${this.apiUrl}/patternType`, { params });
  }

  getPatternType(id: string) {
    return this.http.get(`${this.apiUrl}/patternType/${id}`);
  }

  savePatternType(patternType: PatternTypeI) {
    return this.http.post(`${this.apiUrl}/patternType`, { ...patternType });
  }

  editPatternType(id: string, patternType: PatternTypeI) {
    return this.http.put(`${this.apiUrl}/patternType/${id}`, {
      ...patternType,
    });
  }

  deletePatternType(id: string) {
    return this.http.delete(`${this.apiUrl}/patternType/${id}`);
  }

  getQrCodes(payload: Pagination) {
    let params = new HttpParams({
      fromObject: {
        page: String(payload.page),
        limit: String(payload.limit),
        deleted: String(payload.deleted),
      },
    });
    if (payload.query) {
      params = params.append('query', payload.query);
    }
    return this.http.get(`${this.apiUrl}/qr-code`, { params });
  }

  getQrCodesGenerations(payload: QrCodeGenerationPagination) {
    let params = new HttpParams({
      fromObject: {
        page: String(payload.page),
        limit: String(payload.limit),
        deleted: String(payload.deleted),
      },
    });

    if (payload.isExchange) {
      params = params.append('isExchange', String(payload.isExchange));
    }
    if (payload.material) {
      params = params.append('material', payload.material);
    }
    if (payload.qrCodeType) {
      params = params.append('qrCodeType', payload.qrCodeType);
    }

    return this.http.get(`${this.apiUrl}/qr-codes-generation`, { params });
  }

  createQrCodesGeneration(
    qrCodesGenerationCreateDto: QrCodesGenerationCreateDto,
  ) {
    return this.http.post(
      `${this.apiUrl}/qr-codes-generation`,
      qrCodesGenerationCreateDto,
    );
  }

  downloadXlsFile(id: string): Observable<Blob> {
    return this.http.get(`${this.apiUrl}/qr-codes-generation/${id}/xls`, {
      responseType: 'blob',
    });
  }

  downloadQrCaseXlsFile(id: string): Observable<Blob> {
    return this.http.get(`${this.apiUrl}/case-qr-codes-generation/${id}/xls`, {
      responseType: 'blob',
    });
  }

  getCompanies(payload: Pagination) {
    let params = new HttpParams({
      fromObject: {
        page: String(payload.page),
        limit: String(payload.limit),
        deleted: String(payload.deleted),
      },
    });

    if (payload.query) {
      params = params.append('query', payload.query);
    }
    return this.http.get(`${this.apiUrl}/company`, { params });
  }

  saveCompany(company: CompanyModel) {
    return this.http.post(`${this.apiUrl}/company`, { ...company });
  }

  getOneCompany(id: string) {
    return this.http.get(`${this.apiUrl}/company/${id}`);
  }

  editCompany(company: CompanyModel) {
    return this.http.put(`${this.apiUrl}/company/${company.id}`, {
      ...company,
    });
  }

  searchSalesMan(query: string) {
    const params = new HttpParams({
      fromObject: {
        query,
      },
    });

    return this.http.get(`${this.apiUrl}/sales-man/findWithQuery`, { params });
  }

  deleteCompany(id: string) {
    return this.http.delete(`${this.apiUrl}/company/${id}`);
  }

  changeCompanyPassword(newPassword: NewPasswordModel) {
    return this.http.put(
      `${this.apiUrl}/company/${newPassword.id}/changePassword`,
      { ...newPassword },
    );
  }

  sendResetDbCommand(id: string) {
    return this.http.post(`${this.apiUrl}/command/${id}`, {});
  }

  changeEnvironment(id: string, isNext: boolean) {
    return this.http.post(`${this.apiUrl}/command/${id}/environment`, {
      isNext,
    });
  }

  getCompanyAndSalesPoints(id: string) {
    return this.http.get(`${this.apiUrl}/company/${id}/salesPoints`);
  }

  addSalesPoint(salesPoint: SalesPointModel) {
    return this.http.post(`${this.apiUrl}/salesPoint`, { ...salesPoint });
  }

  getOneSalesPoint(id: string) {
    return this.http.get(`${this.apiUrl}/salesPoint/${id}`);
  }

  editSalesPoint(salesPoint: SalesPointModel) {
    return this.http.put(`${this.apiUrl}/salesPoint/${salesPoint.id}`, {
      ...salesPoint,
    });
  }

  deleteSalesPoint(id: string) {
    return this.http.delete(`${this.apiUrl}/salesPoint/${id}`);
  }

  changeSalesPointPassword(newPassword: NewPasswordModel) {
    return this.http.put(
      `${this.apiUrl}/salesPoint/changePassword/${newPassword.id}`,
      { ...newPassword },
    );
  }

  getTechnicians(payload: Pagination) {
    let params = new HttpParams({
      fromObject: {
        page: String(payload.page),
        limit: String(payload.limit),
        deleted: String(payload.deleted),
      },
    });

    if (payload.query) {
      params = params.append('query', payload.query);
    }

    return this.http.get(`${this.apiUrl}/technician`, { params });
  }

  deleteTechnician(id: string) {
    return this.http.delete(`${this.apiUrl}/technician/${id}`);
  }

  addTechnician(technician: TechnicianModel) {
    return this.http.post(`${this.apiUrl}/technician`, { ...technician });
  }

  changeTechnicianPassword(newPassword: NewPasswordModel) {
    return this.http.put(
      `${this.apiUrl}/technician/${newPassword.id}/changePassword`,
      { ...newPassword },
    );
  }

  getOneTechnician(id: string) {
    return this.http.get(`${this.apiUrl}/technician/${id}`);
  }

  editTechnician(technician: TechnicianModel) {
    return this.http.put(`${this.apiUrl}/technician/${technician.id}`, {
      ...technician,
    });
  }

  getMaterialGroups(payload: Pagination) {
    let params = new HttpParams({
      fromObject: {
        page: String(payload.page),
        limit: String(payload.limit),
        deleted: String(payload.deleted),
      },
    });

    if (payload.query) {
      params = params.append('query', payload.query);
    }

    return this.http.get(`${this.apiUrl}/materialGroup`, { params });
  }

  getMaterialGroupMaterials(id: string) {
    return this.http.get(`${this.apiUrl}/materialGroup/${id}/materials`);
  }

  saveMaterialGroup(materialGroup: MaterialGroup) {
    return this.http.post(`${this.apiUrl}/materialGroup`, { ...materialGroup });
  }

  getOneMaterialGroup(id: string) {
    return this.http.get(`${this.apiUrl}/materialGroup/${id}`);
  }

  editMaterialGroup(id: string, material: MaterialGroup) {
    return this.http.put(`${this.apiUrl}/materialGroup/${id}`, {
      id,
      ...material,
    });
  }

  deleteMaterialGroup(id: string) {
    return this.http.delete(`${this.apiUrl}/materialGroup/${id}`);
  }

  getMaterials(payload: Pagination) {
    let params = new HttpParams({
      fromObject: {
        page: String(payload.page),
        limit: String(payload.limit),
        deleted: String(payload.deleted),
      },
    });

    if (payload.query) {
      params = params.append('query', payload.query);
    }

    return this.http.get(`${this.apiUrl}/material`, { params });
  }

  saveMaterial(material: Material) {
    return this.http.post(`${this.apiUrl}/material`, { ...material });
  }

  getOneMaterial(id: string) {
    return this.http.get(`${this.apiUrl}/material/${id}`);
  }

  editMaterial(id: string, material: Material) {
    return this.http.put(`${this.apiUrl}/material/${id}`, {
      id,
      ...material,
    });
  }

  checkIfPrefixUnique(prefix) {
    return this.http.get(`${this.apiUrl}/material/checkPrefix/${prefix}`);
  }

  getAllMaterials() {
    return this.http.get(`${this.apiUrl}/material/all`);
  }

  getSalesMan(payload: Pagination) {
    let params = new HttpParams({
      fromObject: {
        page: String(payload.page),
        limit: String(payload.limit),
        deleted: String(payload.deleted),
      },
    });

    if (payload.query) {
      params = params.append('query', payload.query);
    }

    return this.http.get(`${this.apiUrl}/sales-man`, { params });
  }

  deleteSalesMan(id: string) {
    return this.http.delete(`${this.apiUrl}/sales-man/${id}`);
  }

  addSalesMan(salesMan: SalesManModel) {
    return this.http.post(`${this.apiUrl}/sales-man`, { ...salesMan });
  }

  changeSalesManPassword(newPassword: NewPasswordModel) {
    return this.http.put(
      `${this.apiUrl}/sales-man/${newPassword.id}/changePassword`,
      { ...newPassword },
    );
  }

  getOneSalesMan(id: string) {
    return this.http.get(`${this.apiUrl}/sales-man/${id}`);
  }

  editSalesMan(salesMan: SalesManModel) {
    return this.http.put(`${this.apiUrl}/sales-man/${salesMan.id}`, {
      ...salesMan,
    });
  }

  getSalesManWithCompanies(id: string) {
    return this.http.get(`${this.apiUrl}/sales-man/${id}/withCompanies`);
  }

  getCompanySalesReport(dates: DatesRangeDTO) {
    const params = new HttpParams({
      fromObject: {
        startAt: dates.startAt.toISOString(),
        endAt: dates.endAt.toISOString(),
      },
    });
    return this.http.get(`${this.apiUrl}/admin/report/companies/successful`, {
      params,
      responseType: 'blob',
    });
  }

  getCompanyErrorsReport(dates: DatesRangeDTO) {
    const params = new HttpParams({
      fromObject: {
        startAt: dates.startAt.toISOString(),
        endAt: dates.endAt.toISOString(),
      },
    });
    return this.http.get(`${this.apiUrl}/admin/report/companies/failed`, {
      params,
      responseType: 'blob',
    });
  }

  getCompanyExchangesReport(dates: DatesRangeDTO) {
    const params = new HttpParams({
      fromObject: {
        startAt: dates.startAt.toISOString(),
        endAt: dates.endAt.toISOString(),
      },
    });
    return this.http.get(`${this.apiUrl}/admin/report/companies/exchanges`, {
      params,
      responseType: 'blob',
    });
  }

  getCompanyAssemblyErrorsReport(dates: DatesRangeDTO) {
    const params = new HttpParams({
      fromObject: {
        startAt: dates.startAt.toISOString(),
        endAt: dates.endAt.toISOString(),
      },
    });
    return this.http.get(
      `${this.apiUrl}/admin/report/companies/assemblyErrors`,
      {
        params,
        responseType: 'blob',
      },
    );
  }

  getSalesManSalesReport(dates: DatesRangeDTO) {
    const params = new HttpParams({
      fromObject: {
        startAt: dates.startAt.toISOString(),
        endAt: dates.endAt.toISOString(),
      },
    });
    return this.http.get(`${this.apiUrl}/admin/report/sales-men/successful`, {
      params,
      responseType: 'blob',
    });
  }

  getSalesManErrorsReport(dates: DatesRangeDTO) {
    const params = new HttpParams({
      fromObject: {
        startAt: dates.startAt.toISOString(),
        endAt: dates.endAt.toISOString(),
      },
    });
    return this.http.get(`${this.apiUrl}/admin/report/sales-men/failed`, {
      params,
      responseType: 'blob',
    });
  }

  getSalesManExchangesReport(dates: DatesRangeDTO) {
    const params = new HttpParams({
      fromObject: {
        startAt: dates.startAt.toISOString(),
        endAt: dates.endAt.toISOString(),
      },
    });
    return this.http.get(`${this.apiUrl}/admin/report/sales-men/exchanges`, {
      params,
      responseType: 'blob',
    });
  }

  getSalesManAssemblyErrorsReport(dates: DatesRangeDTO) {
    const params = new HttpParams({
      fromObject: {
        startAt: dates.startAt.toISOString(),
        endAt: dates.endAt.toISOString(),
      },
    });
    return this.http.get(
      `${this.apiUrl}/admin/report/sales-men/assemblyErrors`,
      {
        params,
        responseType: 'blob',
      },
    );
  }

  getOneSalesManSalesReport(id: string, dates: DatesRangeDTO) {
    const params = new HttpParams({
      fromObject: {
        startAt: dates.startAt.toISOString(),
        endAt: dates.endAt.toISOString(),
      },
    });
    return this.http.get(
      `${this.apiUrl}/admin/report/sales-man/${id}/successful`,
      {
        params,
        responseType: 'blob',
      },
    );
  }

  getOneSalesManErrorsReport(id: string, dates: DatesRangeDTO) {
    const params = new HttpParams({
      fromObject: {
        startAt: dates.startAt.toISOString(),
        endAt: dates.endAt.toISOString(),
      },
    });
    return this.http.get(`${this.apiUrl}/admin/report/sales-man/${id}/failed`, {
      params,
      responseType: 'blob',
    });
  }

  getOneSalesManExchangesReport(id: string, dates: DatesRangeDTO) {
    const params = new HttpParams({
      fromObject: {
        startAt: dates.startAt.toISOString(),
        endAt: dates.endAt.toISOString(),
      },
    });
    return this.http.get(
      `${this.apiUrl}/admin/report/sales-man/${id}/exchanges`,
      {
        params,
        responseType: 'blob',
      },
    );
  }

  getOneSalesManAssemblyErrorsReport(id: string, dates: DatesRangeDTO) {
    const params = new HttpParams({
      fromObject: {
        startAt: dates.startAt.toISOString(),
        endAt: dates.endAt.toISOString(),
      },
    });
    return this.http.get(
      `${this.apiUrl}/admin/report/sales-man/${id}/assemblyErrors`,
      {
        params,
        responseType: 'blob',
      },
    );
  }

  getOneCompanySalesReport(id: string, dates: DatesRangeDTO) {
    const params = new HttpParams({
      fromObject: {
        startAt: dates.startAt.toISOString(),
        endAt: dates.endAt.toISOString(),
      },
    });
    return this.http.get(
      `${this.apiUrl}/admin/report/company/${id}/successful`,
      {
        params,
        responseType: 'blob',
      },
    );
  }

  getOneCompanyErrorsReport(id: string, dates: DatesRangeDTO) {
    const params = new HttpParams({
      fromObject: {
        startAt: dates.startAt.toISOString(),
        endAt: dates.endAt.toISOString(),
      },
    });
    return this.http.get(`${this.apiUrl}/admin/report/company/${id}/failed`, {
      params,
      responseType: 'blob',
    });
  }

  getOneCompanyExchangesReport(id: string, dates: DatesRangeDTO) {
    const params = new HttpParams({
      fromObject: {
        startAt: dates.startAt.toISOString(),
        endAt: dates.endAt.toISOString(),
      },
    });
    return this.http.get(
      `${this.apiUrl}/admin/report/company/${id}/exchanges`,
      {
        params,
        responseType: 'blob',
      },
    );
  }

  getOneCompanyAssemblyErrorsReport(id: string, dates: DatesRangeDTO) {
    const params = new HttpParams({
      fromObject: {
        startAt: dates.startAt.toISOString(),
        endAt: dates.endAt.toISOString(),
      },
    });
    return this.http.get(
      `${this.apiUrl}/admin/report/company/${id}/assemblyErrors`,
      {
        params,
        responseType: 'blob',
      },
    );
  }

  getSyncAccessLog(payload: Pagination) {
    const params = new HttpParams({
      fromObject: {
        page: String(payload.page),
        limit: String(payload.limit),
        deleted: String(payload.deleted),
      },
    });
    return this.http.get(`${this.apiUrl}/access-log/synchronization/all`, {
      params,
    });
  }

  getSalesPointSyncAccessLog(id: string, payload: Pagination) {
    const params = new HttpParams({
      fromObject: {
        page: String(payload.page),
        limit: String(payload.limit),
        deleted: String(payload.deleted),
      },
    });
    return this.http.get(`${this.apiUrl}/access-log/synchronization/${id}`, {
      params,
    });
  }

  deleteSalesPointImei(id: string) {
    return this.http.get(`${this.apiUrl}/salesPoint/${id}/deleteImei`);
  }

  getCaseQrCodes(payload: Pagination) {
    let params = new HttpParams({
      fromObject: {
        page: String(payload.page),
        limit: String(payload.limit),
        deleted: String(payload.deleted),
      },
    });
    if (payload.query) {
      params = params.append('query', payload.query);
    }
    return this.http.get(`${this.apiUrl}/case-qr-code`, { params });
  }

  getCaseQrCodesGenerations(payload: CaseQrCodeGenerationPagination) {
    let params = new HttpParams({
      fromObject: {
        page: String(payload.page),
        limit: String(payload.limit),
        deleted: String(payload.deleted),
      },
    });

    if (payload.feryaPatternId) {
      params = params.append('feryaPatternId', payload.feryaPatternId);
    }
    if (payload.deviceId) {
      params = params.append('deviceId', payload.deviceId);
    }

    return this.http.get(`${this.apiUrl}/case-qr-codes-generation`, { params });
  }

  getAllDevices() {
    return this.http.get(`${this.apiUrl}/device/admin`);
  }

  getDevicePatterns(id: string) {
    return this.http.post(`${this.apiUrl}/pattern/admin`, {
      deviceId: id,
      availableForModule: [ModuleEnum.FeryaCase, ModuleEnum.OnDemand],
    });
  }

  getAllPatterns() {
    return this.http.post(`${this.apiUrl}/pattern/admin`, {
      deviceId: null,
      availableForModule: ModuleEnum.FeryaCover,
    });
  }

  createCaseQrCodesGeneration(payload: CaseQrCodesGenerationCreateDto) {
    return this.http.post(`${this.apiUrl}/case-qr-codes-generation`, {
      ...payload,
    });
  }
}
