import { saveAs as importedSaveAs } from 'file-saver';
import { ApiService } from './api.service';
import * as i0 from "@angular/core";
import * as i1 from "./api.service";
var DownloadService = /** @class */ (function () {
    function DownloadService(api) {
        this.api = api;
    }
    DownloadService.prototype.downloadQrCodesGenerationXlsFile = function (id) {
        this.api.downloadXlsFile(id).subscribe(function (response) {
            importedSaveAs(response, id + ".xls");
        });
    };
    DownloadService.prototype.downloadCaseQrCodesGenerationXlsFile = function (id) {
        this.api.downloadQrCaseXlsFile(id).subscribe(function (response) {
            importedSaveAs(response, id + ".xls");
        });
    };
    DownloadService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DownloadService_Factory() { return new DownloadService(i0.ɵɵinject(i1.ApiService)); }, token: DownloadService, providedIn: "root" });
    return DownloadService;
}());
export { DownloadService };
