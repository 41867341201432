import { Component, OnInit } from '@angular/core';
import { LoginModel } from '@3mk/shared/models/login.model';
import { ApiService } from '../../services/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Message } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  loginForm: LoginModel = {
    email: '',
    password: '',
  };
  msgs: Message[] = [];

  constructor(
    private api: ApiService,
    private translateService: TranslateService,
    private router: Router,
  ) {}

  ngOnInit() {}

  onSubmit() {
    this.api.logIn(this.loginForm).subscribe(
      (res) => {
        localStorage.setItem('auth', JSON.stringify(res));
        this.msgs.push({
          severity: 'success',
          summary: this.translateService.instant('logInPage.success'),
          detail: this.translateService.instant('logInPage.successDetail'),
        });
        setTimeout(() => this.router.navigateByUrl('/companies'), 2000);
      },
      (error: HttpErrorResponse) => this.handleErrors(error),
    );
  }

  handleErrors(error: HttpErrorResponse) {
    this.msgs = [];
    if (error.error.statusCode === 404) {
      this.msgs.push({
        severity: 'error',
        summary: this.translateService.instant('errors.emailNotFound'),
      });
    } else if (error.error.statusCode === 401) {
      this.msgs.push({
        severity: 'error',
        summary: this.translateService.instant('errors.wrongPassword'),
      });
    }
  }
}
